<template>
  <div id="watch" v-resize="checkSize" :class="{'mobile': $vuetify.breakpoint.smAndDown,'large': $vuetify.breakpoint.lgAndUp, 'oops': unpublished || (unpublished && currentVideo.userId != userId)}">
    <main-nav :userId="userId" v-if="$vuetify.breakpoint.mdAndUp"></main-nav>

    <mobile-nav :userId="userId" v-if="$vuetify.breakpoint.smAndDown"></mobile-nav>

      <template v-if="videoLoaded && currentVideo != null">
        <div class="fill-height d-flex align-center justify-center" v-if="videoLoaded && unpublished">
          <v-card class="">
            <v-card-title>Oops...</v-card-title>
            <v-card-text class="">
              This video doesn't exist or hasn;t been published
            </v-card-text>
          </v-card>
        </div>

        <player
          :video="currentVideo"
          :userId="userId"
          :user="user"
          v-if="videoLoaded && !unpublished">
        </player>
      </template>


  </div>
</template>

<script>
// @ is an alias to /src
const player =() => import("@/components/player.vue");
const mainNav =() => import("@/components/shared/nav.vue");
const mobileNav =() => import("@/components/shared/mobile-nav.vue");

import { db } from '@/firebase';
import { mapGetters } from 'vuex';

export default {
  name: "watch",
  metaInfo() {
    return {
      title: `Youtorial: Watch ${this.$route.params.videoId}`
    }
  },
  components: {
    player, mainNav, mobileNav
  },
  computed:{
    ...mapGetters({
      currentVideo: 'GET_CURRENT_VIDEO',
      user: 'GET_USER'
    }),
  },
  data(){
    return{
        videoLoaded: false,
        userId: null,
        unpublished: false,
        video: {'.key': 'fetching ID'},
    }
  },
  methods:{
    //get video info
    fetchVideoDetails(){
      // process.env.NODE_ENV === "development" ? console.log( 'this.currentVideo', this.currentVideo,  ) : null;
      let vThis = this;
      if(this.$isEmpty(this.currentVideo)){
        let videoId = vThis.$route.params.videoId
        let kind = 'watch';
        this.$store.dispatch('getVideo', {videoId, kind})
        .then((results) => {
          vThis.video = results;
          vThis.videoLoaded = true;
          vThis.unpublished = false;
          vThis.userId = vThis.video.userId;
        })
        .catch((err) => {
          vThis.errorMessage = err;
          console.log('err',err);
          vThis.videoLoaded = true;
          vThis.unpublished = true;
        })
      }
      else{
       this.vThis = this.currentVideo;
       vThis.videoLoaded = true;
      }
    },
    checkSize(){
      if(this.$vuetify.breakpoint.mdAndUp){
        // this.$store.commit('TOGGLE_DRAWER', false)
      }
    },
  },
  mounted(){
    this.fetchVideoDetails();
    this.$store.dispatch('toggleDrawer', false);
  },
  beforeDestroy(){
    let video = {}
    this.$store.dispatch('setVideo', video)
  }
};
</script>

<style lang="scss">
%gradientBG{
  background: #FEAC5E!important;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E)!important;  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
#watch{
  height: 100%;
  padding-top: 64px;
  // height: 100vh;
  // display: grid;
  // grid-template-rows:64px 1fr;
  // grid-template-columns: 1fr;
  // grid-template-areas:
  // "header"
  // "video";
  &.oops{
    @extend %gradientBG;
  }
  &.mobile{
    padding-top:0;
  }
  &.large{
    background: rgba(242,242,242,1);
    background: -moz-linear-gradient(left, rgba(242,242,242,1) 0%, rgba(242,242,242,1) 43%, rgba(75,192,200,1) 67%, rgba(199,121,208,1) 78%, rgba(254,171,94,1) 89%, rgba(254,171,94,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(242,242,242,1)), color-stop(43%, rgba(242,242,242,1)), color-stop(67%, rgba(75,192,200,1)), color-stop(78%, rgba(199,121,208,1)), color-stop(89%, rgba(254,171,94,1)), color-stop(100%, rgba(254,171,94,1)));
    background: -webkit-linear-gradient(left, rgba(242,242,242,1) 0%, rgba(242,242,242,1) 43%, rgba(75,192,200,1) 67%, rgba(199,121,208,1) 78%, rgba(254,171,94,1) 89%, rgba(254,171,94,1) 100%);
    background: -o-linear-gradient(left, rgba(242,242,242,1) 0%, rgba(242,242,242,1) 43%, rgba(75,192,200,1) 67%, rgba(199,121,208,1) 78%, rgba(254,171,94,1) 89%, rgba(254,171,94,1) 100%);
    background: -ms-linear-gradient(left, rgba(242,242,242,1) 0%, rgba(242,242,242,1) 43%, rgba(75,192,200,1) 67%, rgba(199,121,208,1) 78%, rgba(254,171,94,1) 89%, rgba(254,171,94,1) 100%);
    background: linear-gradient(to right, rgba(242,242,242,1) 0%, rgba(242,242,242,1) 43%, rgba(75,192,200,1) 67%, rgba(199,121,208,1) 78%, rgba(254,171,94,1) 89%, rgba(254,171,94,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#feab5e', GradientType=1 );

    #player{
      max-width: 1550px;
      margin: 0 auto;
    }
  }
}
</style>
